import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, StaticQuery } from "gatsby"
import { withPreview } from "@prismicio/gatsby-source-prismic-graphql"
import { ContentSection } from "../components/ContentSection"
import { RichText } from "prismic-reactjs"
import SideNav from "../components/SideNav"
import TwoColContainer from "../components/TwoColContainer"
import TopSubNav from "../components/TopSubNav"
import CultureContentContainer from "../components/CultureContentContainer"
import { formatRegularLinks } from "../hooks/useFormattedRegularLinks"

export const query = graphql`
  {
    prismic {
      allCultures {
        edges {
          node {
            seo_page_title
            seo_page_description
            seo_h1
            intro_text
            page_title
            culture_video
            top_sub_nav {
              nav_item
            }
            body {
              ... on PRISMIC_CultureBodyCore_values {
                type
                primary {
                  section_title
                  section_content
                }
              }
              ... on PRISMIC_CultureBodyLatest_articles {
                type
                fields {
                  latest_article {
                    ... on PRISMIC_News {
                      publication_date
                      feature_image
                      title
                      short_description
                      _meta {
                        uid
                      }
                    }
                  }
                }
                primary {
                  section_title
                }
              }
            }
          }
        }
      }
    }
  }
`

const links = [
  {
    key: "about",
    stub: "/about",
    displayName: "About",
  },
  {
    key: "team",
    stub: "/team",
    displayName: "Team",
  },
  {
    key: "capabilities",
    stub: "/capabilities",
    displayName: "Capabilities",
  },
  {
    key: "culture",
    stub: "/culture",
    displayName: "Culture",
  },
  {
    key: "careers",
    stub: "/careers",
    displayName: "Careers",
  },
]

const renderComponent = data => {
  const doc = data.prismic.allCultures.edges.slice(0, 1).pop()
  if (!doc) return null
  const topScrollLinks = doc.node.body.map(b => ({
    id: b.primary.section_title[0].text.replace(" ", "_"),
    label: b.primary.section_title[0].text,
    isVisible: false,
  }))
  const formattedRegularLinks = formatRegularLinks(doc.node.top_sub_nav)
  const seoTitle =
    (doc.node &&
      doc.node.seo_page_title &&
      doc.node.seo_page_title[0] &&
      doc.node.seo_page_title[0].text) ||
    (doc.node &&
      doc.node.page_title &&
      doc.node.page_title[0] &&
      doc.node.page_title[0].text) ||
    ""
  const seoDescription =
    (doc.node &&
      doc.node.seo_page_description &&
      doc.node.seo_page_description[0] &&
      doc.node.seo_page_description[0].text) ||
    ""

  return (
    <Layout>
      <SEO title={seoTitle} description={seoDescription} />
      <TopSubNav
        title={doc.node.page_title[0].text}
        topScrollLinks={topScrollLinks}
        regularLinks={formattedRegularLinks}
      />
      <ContentSection>
        <div className="inner-container">
          <TwoColContainer>
            <div className="left-col">
              <SideNav links={links} />
            </div>
            <div className="right-col">
              {doc.node.seo_h1 && RichText.render(doc.node.seo_h1)}
              <CultureContentContainer doc={doc} />
            </div>
          </TwoColContainer>
        </div>
      </ContentSection>
    </Layout>
  )
}

export default () => {
  return (
    <>
      <StaticQuery query={query} render={withPreview(renderComponent, query)} />
    </>
  )
}
