import React, { useContext } from "react"
import { RichText } from "prismic-reactjs"
import VisibilitySensor from "react-visibility-sensor"
import { ActiveScrollContext } from "../hooks/ActiveScrollContext"
import { linkResolver } from "../utils/linkResolver"
import styled from "styled-components"
import FeaturedArticleCard from "./FeaturedArticleCard"

const VideoContainer = styled("div")`
  width: 100%;
  height: 500px;

  @media screen and (max-width: 480px) {
    height: 250px;
  }
`

const ArticlesContainer = styled("div")`
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-gap: 1rem;

  .featured-article-card:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;

    /* IE11 hack */
    @media all and (-ms-high-contrast: none) {
      margin-right: 1rem;
    }
  }

  .featured-article-card:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 2;

    /* IE11 hack */
    @media all and (-ms-high-contrast: none) {
      margin-right: 1rem;
    }
  }

  .featured-article-card:nth-child(3) {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    -ms-grid-columns: 1fr;
  }
`

const CoreValuesContainer = styled("div")`
  h4 {
    font-size: 2rem;
    margin: 1rem 0;
    font-weight: 700;
    font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
  }

  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;

    @media screen and (max-width: 720px) {
      display: block;
    }

    li {
      font-weight: 600;
      flex: 0 0 50%;
      font-size: 1rem;
      margin-bottom: 1rem;

      @media screen and (max-width: 720px) {
        width: 100%;
      }
    }

    li::before {
      color: #0099cb;
      content: "•";
      padding-right: 1rem;
    }
  }
`

const TagColors = {
  Insights: "#0099CB",
  "Client Results": "#5DB46E",
  "@CRG": "#8CA3B5",
}

const CultureContentContainer = ({ doc }) => {
  const [, setActiveItems] = useContext(ActiveScrollContext)
  function onChange(isVisible, id) {
    setActiveItems({ id, isVisible })
  }
  return (
    <>
      <p class="intro-text">{doc.node.intro_text[0].text}</p>
      <VideoContainer>
        <iframe
          title="Culture video"
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/${doc.node.culture_video.embed_url.replace(
            "https://youtu.be/",
            ""
          )}`}
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </VideoContainer>
      {doc.node.body
        .filter(b => b.type === "core_values")
        .map(b => (
          <VisibilitySensor
            onChange={isVisible =>
              onChange(
                isVisible,
                b.primary.section_title[0].text.replace(" ", "_")
              )
            }
            key={b.primary.section_title[0].text.replace(" ", "_")}
            partialVisibility="top"
          >
            <div>
              <div
                className="anchor"
                id={b.primary.section_title[0].text.replace(" ", "_")}
              />
              <h3>{b.primary.section_title[0].text}</h3>
              <CoreValuesContainer>
                {RichText.render(b.primary.section_content, linkResolver)}
              </CoreValuesContainer>
            </div>
          </VisibilitySensor>
        ))}
      {doc.node.body
        .filter(b => b.type === "latest_articles")
        .map(b => (
          <VisibilitySensor
            onChange={isVisible =>
              onChange(
                isVisible,
                b.primary.section_title[0].text.replace(" ", "_")
              )
            }
            key={b.primary.section_title[0].text.replace(" ", "_")}
            partialVisibility="top"
          >
            <div>
              <div
                className="anchor"
                id={b.primary.section_title[0].text.replace(" ", "_")}
              />
              <h3>{b.primary.section_title[0].text}</h3>
              <ArticlesContainer>
                {b.fields
                  .slice(0, 3)
                  .map(f => ({
                    uid: f.latest_article._meta.uid,
                    tags:
                      f.latest_article._meta.tags &&
                      f.latest_article._meta.tags.map(t => {
                        const color = !TagColors[t] ? "#8CA3B5" : TagColors[t]
                        const label = t
                        return { color, label }
                      }),
                    feature_image:
                      f.latest_article.feature_image &&
                      f.latest_article.feature_image.url,
                    publication_date: !f.latest_article.publication_date
                      ? undefined
                      : new Date(
                          f.latest_article.publication_date.split("-")[0],
                          f.latest_article.publication_date.split("-")[1] - 1,
                          f.latest_article.publication_date.split("-")[2]
                        ),
                    short_description:
                      f.latest_article.short_description[0].text,
                    title: f.latest_article.title[0].text,
                  }))
                  .map(f => (
                    <FeaturedArticleCard key={f.uid} isFirst={false} {...f} />
                  ))}
              </ArticlesContainer>
            </div>
          </VisibilitySensor>
        ))}
    </>
  )
}

export default CultureContentContainer
